<!--
 * @Author: your name
 * @Date: 2020-11-27 09:48:29
 * @LastEditTime: 2020-11-27 10:16:37
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \solax-design\src\views\userManage\userManage.vue
-->
<template>
    <div>
        <div class="search-box">
            <div class="search-box-sub">
                <span class="lable-text">Email</span>
                <el-input v-model="email" :placeholder="$t('common.info.pl-insert-content')"></el-input>
            </div>
            <div class="search-box-sub">
                <span class="lable-text">{{$t('system.user.regi-time')}}</span>
                <el-date-picker
                v-model="startRegTime"
                type="date"
                :placeholder="$t('system.user.choose-time-start')"
                value-format='yyyy-MM-dd'
                @change='changeDateTime'
                :clearable='false'
                :picker-options="pickerOptions"
                >
                </el-date-picker>
                <span>-</span>
                <el-date-picker
                v-model="endRegTime"
                type="date"
                :placeholder="$t('system.user.choose-time-end')"
                value-format='yyyy-MM-dd'
                @change='changeDateTime'
                :clearable='false'
                :picker-options="pickerOptions"
                >
                </el-date-picker>
            </div>
            <div class="search-box-sub">
                <span class="lable-text">{{$t('system.user.login-time')}}</span>
                <el-date-picker
                v-model="startlogTime"
                type="date"
                :placeholder="$t('system.user.choose-time-start')"
                value-format='yyyy-MM-dd'
                @change='changeDateTime'
                :clearable='false'
                :picker-options="pickerOptions"
                >
                </el-date-picker>
                <span>-</span>
                <el-date-picker
                v-model="endlogTime"
                type="date"
                :placeholder="$t('system.user.choose-time-end')"
                value-format='yyyy-MM-dd'
                @change='changeDateTime'
                :clearable='false'
                :picker-options="pickerOptions"
                >
                </el-date-picker>
            </div>
            <div style="margin-left:10px;">
                <el-button type="primary" @click="query" style='margin-left:10px;'>{{$t('common.button.query')}}</el-button>
            </div>
        </div>
        <div class="button-box">
            <el-popconfirm
                    :title="$t('system.user.confirm-reset-pwd')"
                    @confirm='resetPwd()'
            >
                <el-button type="primary" slot="reference" icon='el-icon-edit' class="marginR10" >{{$t('system.user.reset-pwd')}}</el-button>
            </el-popconfirm>
            <el-popconfirm
                    :title="$t('common.button.del-bath-confirm')"
                    @confirm='deleteBatch()'
            >
                <el-button slot="reference" icon='el-icon-delete' class="marginR10" >{{$t('link.delete')}}</el-button>
            </el-popconfirm>
        </div>
        <baseTable v-loading="loading"  row-key='sid' :data="dataRows" border  stripe @selection-change="handleSelectionChange($event)"  :column='column' :select='true'>
            <template #option="{data}">
                <div class="option-box">
                    <el-popconfirm
                            :title="$t('common.button.del-bath-confirm')" @confirm='systemTUserDelete(data.userId)'>
                        <i class="el-icon-delete" slot="reference"></i>
                    </el-popconfirm>
                </div>
            </template>
        </baseTable>
        <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="current"
        :page-sizes="[10, 20, 30, 50]"
        :page-size="size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total">
        </el-pagination>

        <el-dialog
                :title="$t('common.button.del-bath-confirm')"
                :visible.sync="dialogVisible"
                width="30%"
        >
            <el-form ref="form" :model="form" label-width="auto" label-position='right'>
                <el-form-item label="Email" prop="email" :rules="[{ required: true, message: $t('remind.not-null') , trigger: 'change'},
                    {max: 100, message: $t('system.max.length')+100, trigger: 'change'}]">
                    <el-input v-model="form.email"></el-input>
                </el-form-item>
                <el-form-item :label="$t('system.user.lang')" prop="language" :rules="[{ required: true, message: $t('remind.not-null') , trigger: 'change'}]">
                    <el-select v-model="form.language">
                        <el-option label="English" value="0"></el-option>
                        <el-option label="中文" value="1"></el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">{{$t('common.button.cancel')}}</el-button>
                <el-button type="primary" @click="updatePwd('form')">{{$t('common.button.ok')}}</el-button>
            </span>
        </el-dialog>

    </div>
</template>

<script>
import baseTable from '../../components/common/baseTable'
export default {
    components: {
        baseTable
    },
    props: {

    },
    data() {
        return {
            form:{},
            dialogVisible: false,
            email:'',
            startlogTime:"",
            endlogTime:"",
            startRegTime:"",
            endRegTime:"",
            userIds:[],
            email:"",
            end:"",
            pickerOptions:{ //禁用今天之后的时间
                disabledDate(time) {
                    return time.getTime() > Date.now()
                }
            },
            loading:false,
            dataRows:[],
            size:10,
            current:1,
            total:1,
            column:[{
                title: 'Email',
                value: 'email',
            },{
                title: this.$t('system.user.name'),
                value: 'name',
            },{
                title: this.$t('system.user.regi-time'),
                value: 'registerTime',
            },{
                title: this.$t('system.user.login-time'),
                value: 'loginTime',
            },{
                title: this.$t('common.msg.opeation'),
                value: 'option',
                slot: 'option'
            }],
            selectDataList:[]

        };
    },
    computed: {

    },
    watch: {

    },
    created() {

    },
    mounted() {
        this.systemTUserGetPage()
    },
    methods: {

        /*用户列表*/
        async systemTUserGetPage(){
            this.loading = true
            let res = await this.API.systemTUserGetPage({
                email: this.email,
                startlogTime: this.startlogTime,
                endlogTime: this.endlogTime,
                startRegTime: this.startRegTime,
                endRegTime: this.endRegTime,
                current:this.current,
                size:this.size,
            })
            this.dataRows = res.rows
            this.total= parseInt(res.total)
            this.loading = false
        },

        /*删除*/
        async systemTUserDelete(id){
            this.userIds = []
            this.userIds.push(id)
            this.deleteBatch()
        },


        /*重置密码*/
        resetPwd(){
            if(this.userIds.length == 0){
                this.$message({
                    message: this.$t('system.user.chooser-user-first'),
                    type: 'warning'
                });
            }else if(this.userIds.length >1){
                this.$message({
                    message: this.$t('system.user.chooser-one'),
                    type: 'warning'
                });
            }else {
                this.dialogVisible = true
            }
        },


        /*重置密码*/
        async updatePwd(formName){
            let $this = this
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    let userId = $this.userIds[0]
                    if(userId){
                        $this.form.userId = userId
                        $this.resetPwdPost($this.form)
                    }
                } else {
                    return false;
                }
            });
        },


        async resetPwdPost(data){
            let res = await this.API.updateTUserPwdBath(data)
            if(res.success){
                this.dialogVisible = false
                this.$message({
                    message: this.$t('system.user.reset-success'),
                    type: 'success'
                });
            }else{
                this.$message({
                    message: this.$t('system.user.reset-fail'),
                    type: 'warning'
                });
            }
        },


        /*批量删除*/
        async deleteBatch(){
            if (this.userIds==''||this.userIds == []) {
                this.$message({
                    message: this.$t('system.user.chooser-user-first'),
                    type: 'warning'
                });
                return
            }
            let res = await this.API.systemTUserDeleteBath({
                userIds:this.userIds
            })
            if(res.success){
                this.$message({
                    message: this.$t('operate.result.success'),
                    type: 'success'
                });
                this.systemTUserGetPage()
            }else{
                this.$message({
                    message: this.$t('operate.result.fail'),
                    type: 'warning'
                });
            }
        },

        changeDateTime(){

        },
        query(){
            this.current = 1
            this.systemTUserGetPage()
        },
        handleSelectionChange(val){
            this.userIds = val.map( item=>{
                return item.userId
            })
        },
        handleSizeChange(val) {
            this.size = val
            this.current = 1
            this.systemTUserGetPage()
        },
        handleCurrentChange(val) {
            this.current = val
            this.systemTUserGetPage()
        },
    },
};
</script>

<style  lang="less" scoped>

</style>
